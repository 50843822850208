<template>
  <svg
    width="118"
    height="118"
    viewBox="0 0 118 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M93.9278 0H23.1013C10.3428 0 0 10.3428 0 23.1013V93.9278C0 106.686 10.3428 117.029 23.1013 117.029H93.9278C106.686 117.029 117.029 106.686 117.029 93.9278V23.1013C117.029 10.3428 106.686 0 93.9278 0Z"
      fill="#016E7D" />
    <path
      d="M80.1723 35.7545H59.7487L55.7059 28.2466H20.8965V38.7472H49.4056L53.4483 46.2551H82.955C83.9525 46.2551 84.7926 47.0951 84.7926 48.0927V76.4968C84.7926 77.4943 83.9525 78.3344 82.955 78.3344H33.2347C32.2371 78.3344 31.3971 77.4943 31.3971 76.4968V50.5603H20.8965V73.6616C20.8965 82.0096 27.6694 88.835 36.0173 88.835H80.1723C88.5203 88.835 95.2932 82.0621 95.2932 73.6616V50.8754C95.2932 42.5274 88.5203 35.702 80.1723 35.702V35.7545Z"
      fill="#EEFBFF" />
  </svg>
</template>
