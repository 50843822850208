<template>
  <div
    class="flex h-full w-full flex-col items-start justify-start rounded-lg text-center">
    <h1 class="font-semibold">My Profile</h1>
  </div>
</template>
<script>
export default {
  name: "NoFilesSection",
};
</script>
