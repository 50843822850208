<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 21L4.9 15.3C3.99713 13.4948 3.76612 11.4272 4.2483 9.46721C4.73049 7.50724 5.8944 5.78282 7.53176 4.60254C9.16911 3.42225 11.173 2.86315 13.1849 3.02527C15.1968 3.18739 17.0854 4.06017 18.5126 5.4874C19.9398 6.91463 20.8126 8.80318 20.9747 10.8151C21.1369 12.827 20.5777 14.8309 19.3975 16.4682C18.2172 18.1056 16.4928 19.2695 14.5328 19.7517C12.5728 20.2339 10.5052 20.0029 8.7 19.1L3 21Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M2 2L22 22"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
